
import { defineComponent, reactive, ref, watch } from "vue";

export default defineComponent({
  name: "entity-input",
  props: {
    placeholder: String,
    modelValue: String,
    fieldName: String,
    errors: Object,
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
  },
  emits: ["update:modelValue"],
  setup(props, context) {
    let reactiveValue = ref(props.modelValue);

    watch(reactiveValue, () => {
      context.emit("update:modelValue", reactiveValue);
    });

    return { reactiveValue };
  },

  components: {},
});
