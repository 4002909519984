
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";

import ApiService from "@/core/services/ApiService";
import EntityInput from "@/components/EntityInput.vue";
import router from "@/router";

export default defineComponent({
  name: "postback-form",
  props: {
    mode: {
      type: String,
      required: false,
      default: "new",
    },
  },
  components: {
    EntityInput,
  },
  data() {
    return {
      errors: {},
      loading: false,
      entityType: "postback",
      entityData: {},
      statsData: {},
      verticalList: {},
      countryList: {},
    };
  },

  computed: {
    Title() {
      return this.mode == "new" ? "New Offer" : "Postback Settings";
    },
  },
  methods: {
    catchErrors(error) {
      console.log(error.response);
      Swal.fire({
        text: error.response.data.message,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Cerrar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
      this.errors = error.response.data.errors;
      if(error.response && error.response.status != 422) {
        router.back();
      }
    },
    onSaveSuccess(response) {
      this.$router.back();
    },
    retrieveData() {
      this.loading = true;
      ApiService.get(this.entityType)
          .then((result) => {
            this.loading = false;
            this.entityData = result.data.data;
          })
          .catch((err) => {
            this.catchErrors(err);
          });
    },
    submitData() {

        ApiService.post(this.entityType, this.entityData)
            .then(this.onSaveSuccess)
            .catch(this.catchErrors);

    },
  },
  mounted() {
    this.retrieveData();
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Postback Settings");
    });
  },
});
