import { vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59f9e8c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled", "type", "placeholder"]
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("input", {
      disabled: _ctx.disabled,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.reactiveValue) = $event)),
      class: _normalizeClass([[_ctx.errors[_ctx.fieldName] ? 'form-control-danger' : ''], "form-control form-control-lg form-control-solid mb-3 mb-lg-0"]),
      type: _ctx.type,
      placeholder: _ctx.placeholder
    }, null, 10, _hoisted_1), [
      [_vModelDynamic, _ctx.reactiveValue]
    ]),
    (_ctx.errors[_ctx.fieldName])
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors[_ctx.fieldName], (error, id) => {
            return (_openBlock(), _createElementBlock("div", {
              key: id,
              class: "mt-1 text-danger"
            }, _toDisplayString(error), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}